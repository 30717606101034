import React from "react";
import './styles.who.css'

const data = {
    title: "Who We Are",
    content: "Electron Storage Corporation (ESC) was founded in 2014 and is a leading solid-state battery manufacturer and energy storage solutions provider. We are committed to advancing energy storage technology, revolutionizing the future energy landscape and delivering high-quality energy storage solutions globally. Our product lines cater to diverse market needs, while our systems are designed for various applications and project scales. We offer tailored energy storage solutions to meet specific requirements. Join us in creating a greener future through innovative energy storage solutions!"
}

export default function Who() {
    return (
        <div className="who" data-aos='fade-up'>
            <div className="who-title" data-aos='zoom-in'>{data.title}</div>
            <div className="who-content">{data.content}</div>
        </div>
    )
}