import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles.header.css';
import Logo from '../../assets/img/logo.png';
import Logo_mobile from '../../assets/img/logo-moile.png';

function Header() {
    const [isNavVisible, setIsNavVisible] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

    const location = useLocation();
    const currentPath = location.pathname;

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    };

    const handleScroll = useCallback(() => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > 100 && window.scrollY > lastScrollY) {
                setIsHeaderVisible(false); // Scrolling down
            } else {
                setIsHeaderVisible(true); // Scrolling up
            }
            setLastScrollY(window.scrollY);
        }
    }, [lastScrollY]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Add event listener for window resize
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        // Clean up event listeners on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [handleScroll]);

    // Determine if "About Us" or its sub-links are active
    const isAboutUsActive = currentPath.startsWith('/about-us');

    return (
        <>
            <div className={`header ${isHeaderVisible ? 'visible' : 'hidden'}`}>
                <Link to='/'>
                    <div className='header-logo'>
                        <img src={Logo} alt='logo' />
                    </div>
                </Link>
                <div
                    className='burger'
                    onClick={toggleNav}
                    aria-expanded={isNavVisible}
                    aria-label="Toggle navigation"
                >
                    <div className={`line ${isNavVisible ? 'active' : ''}`}></div>
                    <div className={`line ${isNavVisible ? 'active' : ''}`}></div>
                    <div className={`line ${isNavVisible ? 'active' : ''}`}></div>
                </div>
                <div className={`header-nav ${isNavVisible ? 'visible' : ''}`}>
                    <Link to='/' className={`nav ${currentPath === '/' ? 'selected' : ''}`} onClick={toggleNav}>Home</Link>
                    <Link to='/products' className={`nav ${currentPath === '/products' ? 'selected' : ''}`} onClick={toggleNav}>Products</Link>
                    <Link to='/roi-calculator' className={`nav ${currentPath === '/roi-calculator' ? 'selected' : ''}`} onClick={toggleNav}>ROI</Link>

                    {/* About Us Link */}
                    {isMobileView ? (
                        // In mobile view, show "About Us" directly in the nav
                        <div className="nav-dropdown">
                        <Link 
                            to='/about-us/company' 
                            className={`nav ${isAboutUsActive ? 'selected' : ''}`} 
                            onClick={() => {
                                toggleNav();
                            }}
                        >
                            About
                        </Link>
                        <div className="dropdown-content">
                            <Link 
                                to='/about-us/company' 
                                className={`dropdown-link ${currentPath.startsWith('/about-us/company') ? 'selected' : ''}`} 
                                onClick={toggleNav}
                            >
                                About Us
                            </Link>
                            <Link 
                                to='/about-us/faq' 
                                className={`dropdown-link ${currentPath.startsWith('/about-us/faq') ? 'selected' : ''}`} 
                                onClick={toggleNav}
                            >
                                FAQ
                            </Link>
                        </div>
                    </div>
                    ) : (
                        // In desktop view, show the "About" dropdown
                        <div className="nav-dropdown">
                            <Link 
                                to='/about-us/company' 
                                className={`nav ${isAboutUsActive ? 'selected' : ''}`} 
                                onClick={() => {
                                    toggleNav();
                                }}
                            >
                                About
                            </Link>
                            <div className="dropdown-content">
                                <Link 
                                    to='/about-us/company' 
                                    className={`dropdown-link ${currentPath.startsWith('/about-us/company') ? 'selected' : ''}`} 
                                    onClick={toggleNav}
                                >
                                    Company
                                </Link>
                                <Link 
                                    to='/about-us/faq' 
                                    className={`dropdown-link ${currentPath.startsWith('/about-us/faq') ? 'selected' : ''}`} 
                                    onClick={toggleNav}
                                >
                                    FAQ
                                </Link>
                            </div>
                        </div>
                    )}

                    <Link to='/contact' className={`nav ${currentPath === '/contact' ? 'selected' : ''}`} onClick={toggleNav}>Contact Us</Link>
                    <div className='only-mobile burger-logo'>
                        <img src={Logo_mobile} alt='logo' />
                        <div>2024 © Electron Storage Corporation</div>
                    </div>
                </div>
            </div>
            <div className='header-space'></div>
        </>
    );
}

export default Header;
