import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles.product.css';
import RouterBar from '../../components/routerBar';

// import Review from '../../assets/img/review.png'
import Donwload from '../../assets/img/download.png'
import More_img from '../../assets/img/more-img.png'


function ProductDetails() {
	const location = useLocation();
	const params = location.state;

	console.log(params !== null);


	const [qty, setQty] = useState(1);

	const handleCount = (type) => {
		var i = qty;
		if (type === 'plus') {
			i++;
			setQty(i);
		} else if (i > 1) {
			i--;
			setQty(i);
		}
	}

	const routerData = [
		{ router: "Products", link: "/products" },
		{ router: "Product Details", link: `/product` }
	]
	
	const customRouterData = [
		{ router: "Products", link: "/products" },
		{ router: "Custom Solutions", link: `/product/more` }
	]

	return (
		<>
			{params !== null ?
				<div className="">
					<RouterBar router={routerData} />
					<div className='product-detail'>
						<div className='product-detail-img' data-aos='zoom-in'>
							<img src={params.img} alt='img' />
						</div>
						<div className='product-detail-data' data-aos='fade-down'>
							<div className='product-detail-name'>{params.name}</div>
							<div className='product-detail-star'>
								{/* <img src={Review} alt='review' /> */}
							</div>
							<div className='product-detail-sku'>Model #: <span>{params.sku}</span></div>
							{params.characters.map((character, index) => (
								<div key={index} className="feature-item">
									{character}
								</div>
							))}
							<div className='product-detail-capacity'>
								<div>Capacity</div>
								<div className='product-count-select'>
									<select
										name="Capacity"
									// value={value}
									// onChange={handleChange}
									>
										{
											params.capacities.map((item, index) => (
												<option key={index} value={item}>{item}</option>
											))
										}
									</select>
								</div>
							</div>
							<div className='product-detail-qty'>
								<div>Qty</div>
								<div className='product-qty-count'>
									<div onClick={() => handleCount('minus')}>-</div>
									<div>{qty}</div>
									<div onClick={() => handleCount('plus')}>+</div>
								</div>
							</div>
							<Link to='/contact'>
								<div className='product-detail-button'>Contact Us</div>
							</Link>
						</div>
					</div>
					<div className='product-detail-description'>
						<div className='product-description-title'>
							<div>Description</div>
						</div>
						<div className='product-item-value' data-aos='fade-down'>{params.description}</div>
					</div>
					<div className='product-detail-description'>
						<div className='product-specifications-title'>
							<div>Specifications</div>
							<a rel='noreferrer nooopener' target='_blank' href={params.specifications.pdf}>
								<img src={Donwload} alt='download' />
							</a>
						</div>
						{/* <div className='product-specifications-table'> */}
							<div className="table-container custom-scrollbar">
								<table>
									<tbody>
										{params.specifications.table.map((item, index) => (
											<tr key={index}>
												<td className="label">{item.label}</td>
												<td className="value">
													{item.value.split('\n').map((line, i) => (
														<div key={i}>{line}</div>
													))}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						{/* </div> */}
						<div className='product-specifications-img'>
							<img src={params.specifications.img} alt='img' data-aos='zoom-in' />
						</div>
					</div>
				</div> :
				<div>
					<RouterBar router={customRouterData} />
				<div className='product-detail-more'>
					<div className="products-banner">
						<div className="product-banner-sub aos-init aos-animate" data-aos="zoom-in">Custom Solid State Energy Solutions</div>
					</div>
					<div className='product-more-intro'>
						<img src={More_img} alt='more' />
						<div className='product-more-des'>
							<div className='product-more-title'>Tailored to Your Needs</div>
							<div className='product-more-content'>
								At ESC, we specialize in designing and manufacturing fully customizable solid state battery solutions to meet your specific requirements. 
								Whether you need a unique voltage range, capacity, discharge rate, connection interfaces, or custom mounting and housing, we have the expertise to deliver.

							</div>
							<div className='product-more-content'>
								Our systems can be interconnected for seamless integration in parallel or series configurations. We offer fully customized designs, 
								ensuring your battery system is built to meet the exact specifications of your application. From industrial to residential projects, 
								we provide tailored solutions with unmatched precision.

							</div>
							<div className='product-more-content'>
								<strong>
								Let us know your energy storage needs, and we’ll provide you with a custom design and price quote at no cost. We're here to deliver an energy storage system that fits your unique project. We guarantee your satisfaction is our number one priority.</strong>
							</div>
							<Link to='/contact'>
								<div className='product-detail-button'>Contact Us</div>
							</Link>
						</div>
					</div>
				</div>
				</div>
			}
		</>
	);
}

export default ProductDetails;
