import React from "react";
import './styles.ttz.css';

import Img_1 from '../../../../assets/img/ttz-1.png'
import Img_2 from '../../../../assets/img/ttz-2.png'
import Img_3 from '../../../../assets/img/ttz-3.png'

const ttzData = {
    title: ["Accelerating Your", "Transition To Net Zero"],
    sub: "Building a battery that lasts over 20 years more than triples the current standard. A longer lifespan means significantly lower waste. Our batteries are 100% recyclable, unlike others.",
    cards: [
        { 
            content: 'Our solid-state batteries revolutionize energy storage with unparalleled longevity and safety. By lasting greater than two decades and being fully recyclable, they significantly reduce waste, accelerating your transition to Net Zero.', 
            img: Img_1 
        },
        { 
            content: 'Intermittent renewable energy sources present new challenges. Our advanced solid-state energy storage systems stabilize the grid by storing excess energy and providing reliable power during peak demand.', 
            img: Img_2 
        },
        { 
            content: 'ESC is committed to energizing the world safely and responsibly. Our solid-state solutions support renewable energy growth and electric transportation, ensuring a cleaner and more efficient energy landscape.', 
            img: Img_3 
        }
    ]
}


export default function TTZ() {
    return (
        <div className="ttz">
            <div className="ttz-title" data-aos='fade-down'>
                <div className="ttz-title-x">{ttzData.title[0]}</div>
                <div className="ttz-title-y">{ttzData.title[1]}</div>
            </div>
            <div className="ttz-sub" data-aos='fade-down'>{ttzData.sub}</div>
            <div className="ttz-cards">
                {
                    ttzData.cards.map((item) => (
                        <div className="ttz-card-item" data-aos='flip-up'>
                            <img src={item.img} alt="img" />
                            <div className="ttz-item-content">{item.content}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}