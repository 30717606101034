import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.hero.css';

import ESC_model from '../../../assets/img/produc-4.png';
import Tick_icon from '../../../assets/img/tick.png';
import Carousel from '../../Carousel/Carousel';

const heroData = [
    {
        title: 'ESC Graphene Based Solid State Batteries',
        subTitle: 'The Future of Energy Storage has Arrived',
        features: [
            'No Thermal Runaway or associated Fires and Explosions',
            '50,000+ Full Cycles at 100% DOD',
            '20+ Year Lifespan',
            'No Annual Upkeep or Maintenance',
            '100% Fully Recyclable',
        ],
        content: 'Electron Storage Corporation (ESC) delivers cutting-edge energy storage technologies for a cleaner, sustainable world.',
        btn: 'View Products',
        img: ESC_model,
    },
    {
        title: 'Advanced Solid State Design',
        subTitle: 'Graphene and nanotechnology enable high performance storage',
        features: [
            'Best in class energy and power density',
            'Charging and discharging cycle times can be customized for each customers needs',
            'Long operational lifespan from enhanced stability',
            'Thermally stable even with continuous charging/discharging',
            'Environmentally friendly - ESC Battery Systems are 100% Recyclable with no toxic components',
            'Flexible and Scalable specifications for all applications',
            '100% DoD with every cycle and up to 50,000+ cycles'
        ],
        content: '',
        btn: 'View Products',
        img: ESC_model,
    }
];

function Hero() {
    const sliderRef1 = useRef(null);
    const sliderRef2 = useRef(null);
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        const height1 = sliderRef1.current.clientHeight;
        const height2 = sliderRef2.current.clientHeight;
        setMaxHeight(Math.max(height1, height2) + 200);
    }, []);

    const items = [
        <div className="hero" ref={sliderRef1} style={{ height: `${maxHeight}px` }}>
            <div className='hero-content' data-aos='fade-down'>
                <div className='hero-title'>{heroData[0].title}</div>
                <div className='hero-sub-title'>{heroData[0].subTitle}</div>
                <div className='hero-features'>
                    {heroData[0].features.map((item, index) => (
                        <div key={index} className='hero-feature-item'>
                            <img src={Tick_icon} alt='tick' />
                            <div>{item}</div>
                        </div>
                    ))}
                </div>
                <div className='hero-description'>{heroData[0].content}</div>
                <div className='hero-hidden-image'>
                    <img src={heroData[0].img} alt='hero-esc' className='hero-image' />
                </div>
                <div className='sub'>
                    <Link to='/products'>
                        <div className='button-c'>{heroData[0].btn}</div>
                    </Link>
                </div>
            </div>
            <div className='hero-slide' data-aos='fade-down'>
                <img src={heroData[0].img} alt='hero-esc' className='hero-image' />
            </div>
        </div>,
        <div className="hero" ref={sliderRef2} style={{ height: `${maxHeight}px` }}>
            <div className='hero-content' data-aos='fade-down'>
                <div className='hero-title'>{heroData[1].title}</div>
                <div className='hero-sub-title'>{heroData[1].subTitle}</div>
                <div className='hero-features font-mid'>
                    {heroData[1].features.map((item, index) => (
                        <div key={index} className='hero-feature-item'>
                            <img src={Tick_icon} alt='tick' />
                            <div>{item}</div>
                        </div>
                    ))}
                </div>
                <div className='hero-description'>{heroData[1].content}</div>
                <div className='hero-hidden-image'>
                    <img src={heroData[1].img} alt='hero-esc' className='hero-image' />
                </div>
                <div className='sub'>
                    <Link to='/products'>
                        <div className='button-c'>{heroData[1].btn}</div>
                    </Link>
                </div>
            </div>
            <div className='hero-slide' data-aos='fade-down'>
                <img src={heroData[1].img} alt='hero-esc' className='hero-image' />
            </div>
        </div>
    ];

    return (
        <Carousel items={items} />
    );
}

export default Hero;
