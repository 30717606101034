// // src/pages/faq/index.js
// import React, { useState } from 'react';
// import './styles.faq.css';
// import RouterBar from '../../components/routerBar';

// const routerData = [
//   { router: "FAQ", link: "/faq" },
// ];

// // FAQ Data in JSON Format


// function FAQ() {
//   // State to track which FAQ is open
//   const [openIndex, setOpenIndex] = useState(null);

//   // Function to toggle FAQ answers
//   const toggleFAQ = (index) => {
//     setOpenIndex(openIndex === index ? null : index);
//   };

//   return (
//     <div>
//       <RouterBar router={routerData} />
//       <div className="faq">
//         <div className="faq-container">
//           <h1 className="faq-title">Frequently Asked Questions</h1>
//           <div className="faq-list">
//             {faqData.map((faq, index) => (
//               <div
//                 className={`faq-item ${openIndex === index ? 'open' : ''}`}
//                 key={index}
//                 onClick={() => toggleFAQ(index)}
//               >
//                 <div className="faq-question">
//                   {faq.question}
//                   <span className="faq-icon">
//                     {openIndex === index ? '-' : '+'}
//                   </span>
//                 </div>
//                 {openIndex === index && (
//                   <div className="faq-answer">{faq.answer}</div>
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default FAQ;


// src/pages/faq/index.js
import React, { useState } from 'react';
import './styles.faq.css';
import RouterBar from '../../components/routerBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import faqmark from '../../assets/img/faqmark.png'
const routerData = [
    { router: "About", link: "/about-us/company" },
    { router: "FAQ", link: "/faq" },
];

const faqData = {
        "General Information": [
            {
                "question": "What is an ESC Solid-State Battery (SSB)?",
                "answer": "An ESC Solid-State Battery is an advanced energy storage device that uses solid electrolytes instead of liquid ones, offering higher energy density, improved safety, longer cycle life, and wider operating temperature ranges compared to traditional batteries."
            },
            {
                "question": "How does the ESC SSB differ from traditional lithium-ion batteries?",
                "answer": "Unlike traditional lithium-ion batteries, ESC SSBs use solid electrolytes, which reduce the risk of leakage and thermal runaway, provide higher energy density, longer cycle life, and allow for operation at wider temperature ranges."
            },
            {
                "question": "Are custom voltage modules available?",
                "answer": "Yes, while our standard modules have a specific voltage range, we can design custom modules to meet your specific voltage requirements."
            }
        ],
        "Performance and Capabilities": [
            {
                "question": "How fast can ESC SSBs be charged?",
                "answer": "ESC SSBs can potentially be charged faster than traditional batteries due to their solid electrolytes, but charging times depend on the specific technology and design. Please consult our specifications for exact charging rates."
            },
            {
                "question": "How scalable are ESC SSB solutions?",
                "answer": "Our solutions are highly scalable, ranging from 5 kWh to 10 MWh and beyond, suitable for homes, offices, and large industrial applications."
            },
            {
                "question": "How do ESC SSBs handle deep discharges?",
                "answer": "ESC SSBs are designed to handle deep discharges better than traditional batteries, maintaining performance and cycle life even with frequent deep cycling."
            },
            {
                "question": "Do ESC SSBs lose charge when not in use?",
                "answer": "They have a low self-discharge rate, retaining most of their charge over extended periods of inactivity."
            },
            {
                "question": "What is the energy density of ESC SSBs compared to traditional batteries?",
                "answer": "ESC SSBs offer higher energy density than conventional lithium-ion batteries, allowing more energy storage in a smaller footprint."
            }
        ],
        "Safety and Maintenance": [
            {
                "question": "Are ESC SSBs safe to use?",
                "answer": "Yes, they are inherently safer than traditional batteries due to the use of solid electrolytes, which reduce the risk of leakage, fires, and thermal runaway."
            },
            {
                "question": "Do ESC SSBs require any special maintenance?",
                "answer": "No, ESC SSBs are designed to be maintenance-free and require no special upkeep during storage or after installation."
            }
        ],
        "Applications and Compatibility": [
            {
                "question": "Can ESC SSBs replace my existing battery storage system?",
                "answer": "Yes, ESC SSBs can replace or supplement existing battery systems, providing improved performance, safety, and longevity for various applications."
            },
            {
                "question": "What applications are ESC SSBs best suited for?",
                "answer": "They are ideal for energy storage applications requiring high energy density and safety, such as electric vehicles, renewable energy storage, and backup power systems."
            },
            {
                "question": "What inverter systems are compatible with ESC SSBs?",
                "answer": "ESC SSBs are compatible with popular inverter systems like Victron and SMA, ensuring seamless integration into existing setups."
            }
        ],
        "Environmental Impact and Longevity": [
            {
                "question": "What is the expected lifespan of an ESC SSB?",
                "answer": "ESC SSBs have a longer lifespan compared to traditional batteries, with the potential for thousands of charge/discharge cycles and extended calendar life, depending on usage conditions."
            },
            {
                "question": "Can ESC SSBs operate in extreme temperatures?",
                "answer": "Yes, they operate efficiently in a wider temperature range compared to traditional batteries, making them suitable for use in extreme conditions."
            },
            {
                "question": "Are ESC SSBs environmentally friendly?",
                "answer": "Yes, they are made from non-toxic materials, are fully recyclable, and have minimal environmental impact upon disposal."
            }
        ],
        "Cost and Value": [
            {
                "question": "Can ESC SSBs help reduce energy costs?",
                "answer": "Yes, in areas with variable or time-of-use electricity rates, storing energy during off-peak hours and using it during peak times can lead to significant savings."
            },
            {
                "question": "How does the cost of ESC SSBs compare over the long term?",
                "answer": "Although the initial cost may be higher than traditional batteries, the long lifespan, minimal maintenance, and enhanced performance of ESC SSBs can result in lower total cost of ownership over time."
            }
        ]
    };

    function FAQ() {
        // State to track open FAQs per category
        const [openFAQs, setOpenFAQs] = useState({}); // { categoryName: [indexes of open FAQs] }
        const [searchQuery, setSearchQuery] = useState('');
        const [allOpen, setAllOpen] = useState(false); // State for Collapse/Expand All
      
        // Toggle individual FAQ
        const toggleFAQ = (category, index) => {
          setOpenFAQs(prevOpenFAQs => {
            const openIndexes = prevOpenFAQs[category] || [];
            if (openIndexes.includes(index)) {
              // Remove index
              return {
                ...prevOpenFAQs,
                [category]: openIndexes.filter(i => i !== index)
              };
            } else {
              // Add index
              return {
                ...prevOpenFAQs,
                [category]: [...openIndexes, index]
              };
            }
          });
        };
      
        // Collapse or Expand all FAQs
        const toggleAllFAQs = () => {
          if (allOpen) {
            // Collapse all
            setOpenFAQs({});
          } else {
            // Expand all
            const newOpenFAQs = {};
            for (const category in faqData) {
              newOpenFAQs[category] = faqData[category].map((_, index) => index);
            }
            setOpenFAQs(newOpenFAQs);
          }
          setAllOpen(!allOpen);
        };
      
        // Filter FAQs based on search query
        const filteredFAQs = {};
        for (const category in faqData) {
          const filteredQuestions = faqData[category].filter(
            faq =>
              faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
              faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
          );
          if (filteredQuestions.length > 0) {
            filteredFAQs[category] = filteredQuestions;
          }
        }
      
        return (
          <div>
            <RouterBar router={routerData} />
      
            {/* Hero Section */}
            <div className="faq-hero">
              <div className="faq-hero-content">
                <h1 className="faq-hero-title">Frequently Asked Questions</h1>
                {/* Search Bar */}
                <div className="faq-search">
                  <input
                    type="text"
                    placeholder="Search FAQs..."
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                  {searchQuery && (
                    <button className="clear-button" onClick={() => setSearchQuery('')}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                </div>
                {/* Collapse/Expand All Button */}
                <button className="toggle-all-button" onClick={toggleAllFAQs}>
                  {allOpen ? 'Collapse All' : 'Expand All'}
                </button>
              </div>
              <div className="faq-hero-image">
                <img src={faqmark} alt="Question Mark" />
              </div>
            </div>
      
            {/* FAQ Content */}
            <div className="faq">
              <div className="faq-container">
                {Object.keys(filteredFAQs).length === 0 ? (
                  <p>No FAQs found matching your search criteria.</p>
                ) : (
                  Object.entries(filteredFAQs).map(([category, faqs]) => (
                    <div key={category}>
                      <h2 className="faq-category">{category}</h2>
                      {faqs.map((faq, index) => (
                        <div
                          className={`faq-item ${openFAQs[category]?.includes(index) ? 'open' : ''}`}
                          key={index}
                          onClick={() => toggleFAQ(category, index)}
                        >
                          <div className="faq-question">
                            {faq.question}
                            <span className="faq-icon">
                              <FontAwesomeIcon
                                icon={openFAQs[category]?.includes(index) ? faChevronUp : faChevronDown}
                              />
                            </span>
                          </div>
                          {openFAQs[category]?.includes(index) && (
                            <div className="faq-answer">{faq.answer}</div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        );
      }
      
      export default FAQ;